<template>
  <div id="outerDiv" class="container">
    <div id="main" class="container w-50">
      <h1 class="d-flex justify-content-center">404</h1>
      <h2 class="fontFamily">PAGE NOT FOUND</h2>
      <p class="m-auto my-4 fontFamily">
        The page you are looking for might been removed had its name changed
        or is temporarily unavailable
      </p>
    </div>
    <div id="homePage" class="container">
      <RouterLink :to="routeMap.dashboard">
        <button class="btn btn-outline-dark">HOME PAGE</button>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted } from 'vue';
import routeMap from '@/utils/routeMap';

export default {
  name: 'NotFoundError',

  setup () {
    onMounted(() => {
      window.document.body.style.backgroundColor = '#fff';
    });

    onBeforeUnmount(() => {
      window.document.body.style.backgroundColor = '#f5f6fa';
    });

    return {
      routeMap,
    };
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap");

.fontFamily {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

#outerDiv {
  text-align: center;
  margin-top: 5%;
}

#main {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

#homePage {
  display: flex;
  justify-content: center;
  text-align: center;
  /* flex-direction: column; */
}

#ul {
  display: flex;
  justify-content: center;
  /* text-align: center; */
  /* align-items: center; */
  /* flex-direction: row; */
}

h1 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 300px;
}
</style>
